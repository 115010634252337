import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { Container, Row, Col } from 'react-bootstrap';

const SecondPage = () => (
  <Layout>
    <SEO title="Contact The Doctor" />
    <div class="subhead">
      <Container>
        <h1>Contact The Doctor</h1>
      </Container>
    </div>

    <Container>
        <Row>
          <Col lg={6}>
            <p>Thank you! You're message has been sent!</p>
          </Col>
        </Row>
        
      </Container>
  </Layout>
)

export default SecondPage
